import React, { useState, useCallback, useEffect } from 'react'
import { motion, Variants } from 'framer-motion'
import classNames from 'classnames'

interface CustomWindow extends Window {
  ethereum?: any // Define ethereum property as optional
}

declare const window: CustomWindow

export default function App() {
  // const searchParams = new URLSearchParams(window.location.search)

  enum ViewState {
    Loading,
    Ready,
    Error,
  }

  const [viewState, setViewState] = useState(ViewState.Ready)

  const [inputToken, setInputToken] = useState('')
  const [outputToken, setOutputToken] = useState('')
  const [inputAmount, setInputAmount] = useState(0)
  const [outputAmount, setOutputAmount] = useState(0)

  const [isInputFocused, setInputFocused] = useState(false)

  const handleSwap = () => {
    alert('Not implimented')
    // Implement token swap logic here
    console.log(
      `Swapping ${inputAmount} ${inputToken} for ${outputAmount} ${outputToken}`,
    )
  }

  async function onMount() {
    if (!(window as any).ethereum) {
      throw new Error('[PAY:switchChains] no window.etherum')
    }

    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    })

    if (!accounts[0]) throw new Error('connect wallet webview - no account')

    await (window as any).ethereum.request({
      method: `wallet_switchEthereumChain`,
      params: [
        {
          chainId: (137).toString(16),
        },
      ],
    })
  }

  useEffect(() => {
    onMount()
  }, [])

  //if (viewState === ViewState.Loading) {
  //return <Loading />
  // }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <main className="fixed top-0 left-0 right-0 bottom-0 bg-dark overflow-hidden">
      <div className="px-4 max-w-screen-sm mx-auto overflow-hidden">
        <div className="flex items-center">
          <img
            alt="Bird logo"
            className="h-6"
            src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/brand/bird.png"
          />
          <span className="font-bold inline-block px-4 py-2 text-white bg-light rounded-2xl my-3 ml-3">
            Fund Wallet
          </span>
        </div>

        <div
          className={`flex flex-col mb-0.4 bg-light rounded-2xl p-4 ${
            isInputFocused ? 'border border-active' : 'border border-default'
          }`}
        >
          <p className="text-gray-400 text-sm">You pay</p>

          <div className="flex flex-row">
            <input
              inputMode="decimal"
              autoComplete="off"
              autoCorrect="off"
              type="text"
              pattern="^[0-9]*[.,]?[0-9]*$"
              placeholder="0"
              minLength={1}
              maxLength={79}
              spellCheck="false"
              className="w-1/2 px-0 py-2 rounded-lg bg-transparent focus:outline-none focus:border-none text-white text-3xl hide-number-arrows"
              value={inputAmount}
              style={{ WebkitAppearance: 'none', margin: 0 }}
              onChange={(e) => setInputAmount(parseFloat(e.target.value))}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              autoFocus
            />
            <div className="w-1/2 flex justify-end">
              <select
                className="px-3 py-2 border-dark rounded-3xl bg-transparent text-white font-bold text-xl bg-dark focus:outline-none focus:border-none"
                value={inputToken}
                onChange={(e) => setInputToken(e.target.value)}
              >
                <option value="">Select Token</option>
                {/* Add options for available tokens */}
                <option value="ETH">ETH</option>
                <option value="USDC">USDC</option>
                <option value="DAI">DAI</option>
              </select>
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <p className="pt-3 text-gray-400 text-sm">Balance: 0</p>
          </div>
        </div>

        {/* <div className="bg-light w-20 h-20">V</div>*/}

        <div className="arrow-wrapper">
          <div color="#FFFFFF" className="swap-currency-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#FFFFFF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <polyline points="19 12 12 19 5 12"></polyline>
            </svg>
          </div>
        </div>

        <div className="flex flex-col  mb-4 bg-light rounded-2xl p-4">
          <p className="text-gray-400 text-sm">You receive</p>
          <div className="flex align-center">
            <p className="w-1/2 px-0 py-2 rounded-lg bg-transparent focus:outline-none focus:border-none text-gray-400 text-3xl">
              {formatter.format(outputAmount)}
            </p>

            <div className="w-1/2 flex align-center items-center justify-end pr-16">
              <img
                className="h-6"
                src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/brand/bird.png"
              />
              <p className="text-white ml-3 text-xl font-bold">Ca$h</p>
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <p className="pt-3 text-gray-400 text-sm">Balance: 0</p>
          </div>
        </div>

        <button
          className="w-full font-bold bg-pink-500 text-white px-4 py-3 text-xl rounded-xl"
          onClick={handleSwap}
          // disabled={!inputToken || !outputToken || inputAmount <= 0}
        >
          Swap
        </button>
      </div>
    </main>
  )
}

const EnterButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: (e: any) => Promise<void>
}) => {
  //const onClick = React.useCallback(() => onInput(input), [onInput, input])

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = useCallback(
    (e: any) => {
      onClick(e)
      setIsClicked(true)
      setTimeout(() => setIsClicked(false), 150)
    },
    [onClick],
  )

  const variants: Variants = {
    clicked: { scale: 1.4 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      className={classNames(
        'select-none',
        'mx-auto',
        'bg-neutral-900',
        'flex',
        'items-center',
        'justify-center',
        'text-gray-100',
        //  'py-5',
        'rounded-md',
        'transition',
        'duration-150',
        'shadow',
        'bg-white',
        'dark:bg-black',
        'text-neutral-300',
        'dark:text-neutral-300',
        'h-14',
        'font-medium',
        //'text-base',
        'w-full',
        'dark:border-neutral-900',
        'border-0',
        'text-xl',
      )}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {children}
    </motion.button>
  )
}
